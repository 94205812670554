import React, { Component } from 'react';
import Blogsidebar from './Blog_sidebar';
import Header from '../layout/Header';

export class Blog extends Component {
  constructor(props){
    super();
}
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box">
                                    <div className="page-title-heading">
                                        <h1 className="title">Blog</h1>
                                        </div>{/* /.page-title-captions */}
                                        <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                        <span>Blog</span>
                                    </div>
                                </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}
                <div className="ttm-row blog-classic-section clearfix">
                    <div className="container-xl">
                        <div className="row multi-columns-row">
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">15 Dec 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">5 Easy Ways to Improve Your Web Security</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">10 Nov 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">How Can I Manage IT Services in Business ?</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">16 Feb 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">Digital Conference Of IT Tech Events in 2019</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">01 Aug 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">Where And How To Watch Live Stream Today</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">10 May 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">Define world best IT technology</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-12">
                                <div class="featured-imagebox-post-style1">
                                    <div class="featured-post-overlay">
                                        <div class="featured-post-thumbnail">
                                            <img class="img-fluid ttm-auto" src="images/blog/blog-04-828x894.png" loading="lazy" alt="image" />
                                        </div>
                                        <div class="featured-post-content">
                                            <div class="post-entry-date">25 Sep 2020</div>
                                            <div class="ttm-post-title">
                                                <h3 class="post-h3">
                                                <a href={process.env.PUBLIC_URL + '/Single_blog'} class="post-link">Define World Best IT Solution Technology</a>
                                                </h3>
                                            </div>
                                            <div class="ttm-post-catagory">
                                                <div class="catagory-text">By John Doe</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default Blog;