import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import CountUp from 'react-countup';
import Detailbox from './Detail_box';

export class Single_style1 extends Component {
    render() {
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12"> 
                                <div className="title-box">
                                    <div className="page-title-heading">
                                        <h1 className="title">Project Details</h1>
                                        </div>{/* /.page-title-captions */}
                                        <div className="breadcrumb-wrapper">
                                        <span>
                                            <a title="Homepage" href={process.env.PUBLIC_URL + '/'}>&nbsp;&nbsp;Home</a>
                                        </span>
                                        <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                                        <span>Project Details</span>
                                    </div>
                                </div>
                            </div>{/* /.col-md-12 */}  
                        </div>{/* /.row */}  
                    </div>{/* /.container */}                      
                </div>
                {/* page-title end*/}

                <section class="ttm-row product-details clearfix">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="ttm-Product-details pl-70 pr-70 res-991-pr-0 res-991-pl-0 res-991-pr-0">
                                    <div class="ttm_pf_image-wrapper">
                                        <img width="1200" height="800" class="img-fluid" src="images/portfolio/portfolio-12-1200x800.jpg" alt="portfolio-img" />
                                    </div>
                                    <div class="ttm-bgcolor-grey spacing-5">
                                        <div class="ttm-populated-box-main">
                                            <div class="ttm-populatedbox"> 
                                                <div class="ttm-populated-box">
                                                    <h3 class="ttm-populated-h3-2">Category</h3>
                                                    <p>Bussiness, workshop</p>
                                                </div>
                                                <div class="ttm-populated-box">
                                                    <h3 class="ttm-populated-h3-2">Services</h3>
                                                    <p>IT-Tech.com</p>
                                                </div>
                                                <div class="ttm-populated-box">
                                                    <h3 class="ttm-populated-h3-2">Client</h3>
                                                <p>Alexa martin</p>
                                                </div>
                                            </div>
                                            <div class="ttm-populatedbox"> 
                                                <div class="ttm-populated-box">
                                                    <h3 class="ttm-populated-h3-2">Date</h3>
                                                    <p>22 october 19</p>
                                                </div>
                                                <div class="ttm-populated-box last-child">
                                                    <h3 class="ttm-populated-h3-2">Share On</h3>
                                                    <ul class="ttm-populated-list">
                                                        <li class="ttm-populated-list-item">
                                                        <a href="#" class="ttm-populated-list-link-2"><i class="fab fa-facebook-f"></i></a>
                                                        </li>
                                                        <li class="ttm-populated-list-item">
                                                        <a href="#" class="ttm-populated-list-link-2"><i class="fab fa-twitter"></i></a>
                                                        </li>
                                                        <li class="ttm-populated-list-item">
                                                        <a href="#" class="ttm-populated-list-link-2"><i class="fab fa-instagram"></i></a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row align-items-center mt-50">
                                        <div class="col-xl-6 col-lg-12">
                                            <div class="ttm_pf_image-wrapper pr-10">
                                                <img width="615" height="360" class="img-fluid w-100" src="images/portfolio/portfolio-13-951x557.jpg" alt="portfolio-img" />
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-12">
                                            <div class="res-1199-mt-30 pl-10">
                                                <p className="pb-20">An advertising agency can be characterized as bundles of exercises offered to different organizations that concern the arranging, generation, and conveyance of advertising.Advertising is relied upon to impact the states of mind, expectations, and conduct of the customer’s clients and in this way.It regularly supplies supporting statistical</p>
                                                <div class="row no-gutters ttm-vertical_sep style3 ttm-textcolor-dark">
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="ttm-fid inside ttm-fid-3 style3 first-child">
                                                        <div class="ttm-fid-contents text-left">
                                                            <CountUp start={0} end={25} duration={3} />
                                                            <h3 class="ttm-fid-title">Years of experience</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="ttm-fid inside ttm-fid-3 style3">
                                                        <div class="ttm-fid-contents text-left">
                                                            <CountUp start={0} end={15} duration={3} />
                                                            <span>k</span>
                                                            <h3 class="ttm-fid-title">Freelancers</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                    <div class="ttm-fid inside ttm-fid-3 style3">
                                                        <div class="ttm-fid-contents text-left">    
                                                            <CountUp start={0} end={60} duration={3} />
                                                            <span>+</span>
                                                            <h3 class="ttm-fid-title">Project done</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ttm-horizontal_sep mt-50 mb-30 res-991-mt-30 res-575-mt-20"></div>
                                    <div class="pf-single-btn text-right">
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-border ttm-btn-color-darkgrey mr-20" href="#">Next</a>
                                        <a class="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-border ttm-btn-color-darkgrey" href="#">Previous</a>
                                    </div>
                                    <div class="row mt-45 res-991-mt-30">
                                        <div class="col-lg-12">
                                            <h3 class="pf-single-related-title-2">Related Projects</h3>
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                                            <div class="featured-imagebox featured-imagebox-portfolio style1"> 
                                                <div class="featured-link">
                                                    <div class="featured-thumbnail">                                  
                                                        <img class="img-fluid" width="635" height="800" src="images/portfolio/pf-04-635x800.jpg" alt="gallery-img" />                                     
                                                    </div>
                                                    <div class="imagebox-portfolio-bg-overlay"></div>
                                                    <div class="imagebox-content-ttmfolio">
                                                        <div class="imagebox-category-portfolio">
                                                        <a href="#" class="category-portfolio">Corporate</a>
                                                        <div class="category-sep">,</div>
                                                        <a href="#" class="category-portfolio">Workshop</a>
                                                        </div>
                                                        <div class="imagebox-title-portfolio">
                                                        <h3 class="imagebox-heading-portfolio">
                                                        <a href={process.env.PUBLIC_URL + '/project_details'} class="imagebox-heading-portfolio-link">Product design</a>
                                                        </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                    
                                        </div>
                                        <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                                            <div class="featured-imagebox featured-imagebox-portfolio style1"> 
                                                <div class="featured-link">
                                                    <div class="featured-thumbnail">                                  
                                                        <img class="img-fluid" width="635" height="800" src="images/portfolio/pf-04-635x800.jpg" alt="gallery-img" />                                     
                                                    </div>
                                                    <div class="imagebox-portfolio-bg-overlay"></div>
                                                    <div class="imagebox-content-ttmfolio">
                                                        <div class="imagebox-category-portfolio">
                                                        <a href="#" class="category-portfolio">Campaign</a>
                                                        <div class="category-sep">,</div>
                                                        <a href="#" class="category-portfolio">Corporate</a>
                                                        </div>
                                                        <div class="imagebox-title-portfolio">
                                                        <h3 class="imagebox-heading-portfolio">
                                                        <a href={process.env.PUBLIC_URL + '/project_details'} class="imagebox-heading-portfolio-link">IT Consultancy</a>
                                                        </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


export default Single_style1;