import React, { Component } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";

export class Menu extends Component {
  render() {
    return (
      <nav id="menu" role="navigation" className="menu">
        <ul>
          <li className="menu-hasdropdown">
            <a href={process.env.PUBLIC_URL + "/"} className="mega-menu-link">
              Ana Sayfa
            </a>
          </li>
          <li className="menu-hasdropdown">
            {/* <a>Sayfalar</a>
            <ul className="menu-dropdown">
              <li>
                <a href={process.env.PUBLIC_URL + "/aboutus_01"}>About Us 1</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/Services_01"}>Services</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/Our_team"}>Our Team</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/team_detail"}>
                  Team Detail
                </a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/Error"}>Error Page</a>
              </li>
            </ul> */}
          </li>
          <li className="menu-hasdropdown">
            <a>Hizmetlerimiz</a>
            <ul className="menu-dropdown">
              <li>
                <a href={process.env.PUBLIC_URL + "/insaat"}>İnşaat</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/hafriyat"}>Hafriyat</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/nakliyat"}>Nakliyat</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/taahhut"}>Taahhüt</a>
              </li>
            </ul>
          </li>
          {/* <li className="menu-hasdropdown">
            <a href={process.env.PUBLIC_URL + "/projeler"}>Projeler</a>
          </li> */}
          {/* <li className="menu-hasdropdown">
            <a>Blog asdas</a>
            <ul className="menu-dropdown">
              <li>
                <a href={process.env.PUBLIC_URL + "/blog"}>Blog Classic</a>
              </li>
              <li>
                <a href={process.env.PUBLIC_URL + "/single_blog"}>
                  Blog Single View
                </a>
              </li>
            </ul>
          </li> */}
          <li className="menu-hasdropdown">
            <a
              href={process.env.PUBLIC_URL + "/iletisim"}
              className="mega-menu-link"
            >
              İletişime Geç
            </a>
          </li>
        </ul>
      </nav>
    );
  }
}
