import React, { Component } from "react";
import Slider from "react-slick";
import { Header } from "./layout/Header";

const images = [
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
  "https://via.placeholder.com/600X750/444444.jpg",
];

export class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }
  render() {
    var slick_slider = {
      dots: false,
      arrow: false,
      autoplay: true,
      infinite: true,
      speed: 1000,
      slidesToShow: 4,
      slidesToScroll: 1,
      rows: 1,

      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 575,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    var slick_slider_ltr = {
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: true,
      centerMode: false,
      autoplaySpeed: 0,
      speed: 9000,
      cssEase: "linear",
      pauseOnHover: true,
      slidesToShow: 3,
      slidesToScroll: 1,
    };

    var slick_slider_rtl = {
      infinite: true,
      arrows: false,
      dots: false,
      autoplay: true,
      centerMode: false,
      rtl: true,
      autoplaySpeed: 0,
      speed: 9000,
      cssEase: "linear",
      pauseOnHover: true,
      slidesToShow: 3,
      slidesToScroll: 1,
    };

    return (
      <div className="site-main">
        <Header />

        <section className="ttm-row banner-section ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img4 overflow-hidden clearfix">
          <div class="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
          <div class="bg-image-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-11 text-center m-auto">
                <div class="section-title style4">
                  <div className="title-header">
                    <h2 class="title">Güç Grup </h2>
                    <div class="banner-text">
                      <div class="w-layout-grid grid-24">
                        <p class="text-right pt-60 res-1400-text-center">
                          Verdiğimiz tüm hizmetlerde
                          <br /> güçlü ve sağlam çözümler sunuyoruz.
                        </p>
                        <h2 class="title position-relative right_20">
                          <div className="ttm-textcolor-skincolor d-inline-block">
                            güven
                          </div>
                        </h2>
                      </div>
                    </div>
                    <h2 class="title"> hız, kalite </h2>
                  </div>
                </div>
                <div class="banner-buttons text-center res-991-mb-0 res-575-mt-0 res-575-mb-0 mt-50 mb-40">
                  {/* <a
                    class="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-fill ttm-btn-color-gradiant mr-20 mb-20 res-991-mb-10 d-inline-block"
                    href={process.env.PUBLIC_URL + "/iletisim"}
                  >
                    start free trail!
                  </a> */}
                  {/* <p className=" d-inline-block text-left">
                    High degree of the sensitivity and specificity <br /> to
                    prescribe recommended actions
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* aboutus-section */}
        <section class="ttm-row services-section clearfix">
          <div class="container">
            <div class="row">
              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="section-title res-991-mb-0">
                  <div class="title-header">
                    <h2 class="title">
                      Güçlü çözümlerle geleceği birlikte inşaa ediyoruz.
                    </h2>
                  </div>
                </div>
              </div>
              <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
                <div class="process-desc pl-90 res-1199-pl-0 pt-0">
                  <p class="desc-text">
                    Güç Grup, inşaat, hafriyat, taahhüt ve nakliyat
                    sektörlerinde yılların deneyimiyle projelerinizi güvenle
                    tamamlar. Güçlü altyapımız ve uzman kadromuzla, her aşamada
                    profesyonel çözümler sunarak projelerinizi zamanında ve
                    yüksek kalitede teslim ederiz. Sektördeki bilgi
                    birikimimizle, büyük ya da küçük tüm projelerde
                    sürdürülebilir ve yenilikçi hizmet anlayışımızla
                    yanınızdayız.
                  </p>
                </div>
              </div>
            </div>
            <div class="row row-equal-height">
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="featured-icon-box style1 first-child">
                  <div class="featured-icon-thumnail">
                    <img
                      src="images/icon-01.png"
                      loading="lazy"
                      alt="Icon"
                      class="rotate-img img-fluid"
                    />
                  </div>
                  <div class="featured-content">
                    <h3 class="featured-title-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/insaat"}
                        class="featured-title-h3-link"
                      >
                        İnşaat
                      </a>
                    </h3>
                    <div class="featured-bottom-btn">
                      <a
                        href={process.env.PUBLIC_URL + "/insaat"}
                        class="btn btn-inline"
                      >
                        <i class="fas fa-long-arrow-alt-up"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="featured-icon-box style1 border-none">
                  <div class="featured-icon-thumnail">
                    <img
                      src="images/icon-02.png"
                      loading="lazy"
                      alt="Icon"
                      class="rotate-img img-fluid"
                    />
                  </div>
                  <div class="featured-content">
                    <h3 class="featured-title-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="featured-title-h3-link"
                      >
                        Hafriyat
                      </a>
                    </h3>
                    <div class="featured-bottom-btn">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="btn btn-inline"
                      >
                        <i class="fas fa-long-arrow-alt-up"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="featured-icon-box style1 res-991-pl-0">
                  <div class="featured-icon-thumnail">
                    <img
                      src="images/icon-03.png"
                      loading="lazy"
                      alt="Icon"
                      class="rotate-img img-fluid"
                    />
                  </div>
                  <div class="featured-content">
                    <h3 class="featured-title-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="featured-title-h3-link"
                      >
                        Nakliyat
                      </a>
                    </h3>
                    <div class="featured-bottom-btn">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="btn btn-inline"
                      >
                        <i class="fas fa-long-arrow-alt-up"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6 col-sm-6 col-12">
                <div class="featured-icon-box style1 last-child">
                  <div class="featured-icon-thumnail">
                    <img
                      src="images/icon-04.png"
                      loading="lazy"
                      alt="Icon"
                      class="rotate-img img-fluid"
                    />
                  </div>
                  <div class="featured-content">
                    <h3 class="featured-title-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="featured-title-h3-link"
                      >
                        Taahhüt
                      </a>
                    </h3>
                    <div class="featured-bottom-btn">
                      <a
                        href={process.env.PUBLIC_URL + "/content_engineering"}
                        class="btn btn-inline"
                      >
                        <i class="fas fa-long-arrow-alt-up"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* aboutus-section end */}

        <section class="ttm-row zero-padding-section ttm-bg z-index-0 clearfix res-991-pt-0">
          <div class="ttm-row-wrapper-bg-layer ttm-bg-layer spacing-1"></div>
          <div class="container">
            <div class="row no-gutters">
              <div class="col-xl-6 col-lg-6">
                <div class="ttm-bg ttm-col-bgimage-yes col-bg-img-one ttm-left-span">
                  <div class="ttm-col-wrapper-bg-layer ttm-bg-layer"></div>
                  <div class="layer-content"></div>
                </div>
                <img
                  class="img-fluid ttm-equal-height-image w-100 position-relative"
                  src="images/hafriyat1.jpg"
                  alt="bg-image"
                />
              </div>
              <div class="col-xl-6 col-lg-6">
                <div class="ttm-bgcolor-grey position-relative ttm-right-span spacing-1">
                  <div class="ttm-col-wrapper-bg-layer ttm-bg-layer">
                    <div class="ttm-col-wrapper-bg-layer-inner"></div>
                  </div>
                  <div class="layer-content">
                    <div class="position-relative">
                      <div class="section-title with-desc clearfix">
                        <div class="title-header">
                          <h2 className="title">
                            Güçlü Projeler, Güvenli Yarınlar.
                          </h2>
                        </div>
                        <div class="title-desc">
                          <p>
                            Güç Grup, inşaat, hafriyat, nakliyat ve taahhüt
                            alanlarında güvenilir ve yenilikçi çözümler sunan
                            bir firma olarak, projelerinizi zamanında ve
                            kaliteli bir şekilde hayata geçirmeyi taahhüt eder.
                          </p>
                        </div>
                      </div>
                      <div class="row row-equal-height mt-40">
                        <div class="col-lg-6">
                          <div class="featured-box-main">
                            <h3 class="featured-box-h3">
                              Modern inşaat teknikleri
                            </h3>
                            <div class="ttm-horizontal_sep mt-30 mb-30 border-none res-991-mb-15 res-991-d-none res-991-mt-15"></div>
                            <h3 class="featured-box-h3">Geniş araç filosu</h3>
                          </div>
                        </div>
                        <div class="col-lg-6">
                          <div class="featured-box-main">
                            <h3 class="featured-box-h3">
                              Sorunsuz lojistik çözümleri
                            </h3>
                            <div class="ttm-horizontal_sep mt-30 mb-30 border-none res-991-mb-15 res-991-d-none res-991-mt-15"></div>
                            <h3 class="featured-box-h3">
                              Anahtar teslim projeler
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="ttm-row first-row-title-section ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img1 clearfix">
          <div class="ttm-row-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-darkgrey"></div>
          <div class="container">
            <div class="row">
              <div class="col-lg-2 col-md-12">
                <div class="position-relative">
                  <h2 class="row-heading-h3 res-991-pt-0">
                    Güç <br />
                    Grup
                  </h2>
                </div>
              </div>
              <div class="col-lg-10 col-md-12">
                <div class="position-relative res-991-mt-20 res-991-pb-0 pb-25">
                  <h2 class="row-heading-h2">Hızlı, güvenilir</h2>
                </div>
              </div>
            </div>
            <div class="row mt-50 res-991-mt-20">
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/6.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Bina, Fabrika Temel Kazı ve Dolguları
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/2.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Üst Yapı - Yol Yapım İşleri
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/3.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Altyapı - Temiz ve Atık Su İsale Hatları
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/1.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Ocak Malzemesi Temini
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/5.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        İş Makinası ve Kamyon Kiralama
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/4.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Bina Yıkımı
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/7.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Dekapaj Yapım İşleri
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="service-box style1">
                  <div class="service-box-thumnail">
                    <a href={process.env.PUBLIC_URL + "/project_details"}>
                      <img
                        width="654"
                        height="490"
                        class="img-fluid"
                        src="images/8.png"
                        alt="image"
                      />
                    </a>
                  </div>
                  <div class="service-box-content">
                    <h3 class="service-box-h3">
                      <a
                        href={process.env.PUBLIC_URL + "/project_details"}
                        class="service-box-link"
                      >
                        Taahhüt İşleri
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ttm-row step-section-1 clearfix">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                <div class="section-title style2">
                  <div class="title-header">
                    <h2>Güçlü Altyapı</h2>
                  </div>
                </div>
              </div>
              <div class="col-xl-5 col-lg-5 col-md-12 col-sm-12 ">
                <div class="process-desc">
                  <p class="desc-text">
                    Güç Grup olarak inşaat, hafriyat, nakliyat ve taahhüt
                    alanlarında sektöre yön veren çözümler sunuyoruz. Uzman
                    ekibimiz ve modern teknolojilerimizle projelerinizi
                    zamanında ve eksiksiz tamamlıyoruz. Dayanıklı yapılar,
                    güvenli altyapılar ve sorunsuz lojistik hizmetlerle, her
                    adımda müşterilerimize yüksek kaliteli hizmet sunmayı
                    hedefliyoruz. İster büyük ölçekli projeler, ister küçük
                    çaplı işler olsun, her projede profesyonellik ve güven
                    önceliğimizdir.
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="ttm-horizontal_sep mt-85 mb-40 res-991-mt-40"></div>
                <div class="ttm-processbox-wrapper">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="ttm-box-content">
                        <div class="ttm-hovertext1">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-hovertext2">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-box-content-title res-991-pt-0">
                          <h3 class="ttm-box-title-h3">Güven</h3>
                          <p>
                            Projeleri zamanında ve eksiksiz teslim ediyoruz.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="ttm-box-image res-991-mt-15">
                        <img
                          width="965"
                          height="240"
                          class="proces-img img-fluid"
                          src="images/step1.png"
                          loading="lazy"
                          alt="images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="ttm-horizontal_sep mt-25 mb-40"></div>
                <div class="ttm-processbox-wrapper">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="ttm-box-content res-991-mt-15">
                        <div class="ttm-hovertext1">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-hovertext2">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-box-content-title res-991-pt-0">
                          <h3 class="ttm-box-title-h3">Uzmanlık</h3>
                          <p>Deneyimli ekibimizle kusursuz hizmet sunuyoruz.</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="ttm-box-image res-991-pb-30">
                        <img
                          width="965"
                          height="240"
                          class="proces-img img-fluid"
                          src="images/step3.png"
                          loading="lazy"
                          alt="images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="ttm-horizontal_sep mt-25 mb-40 res-991-mt-0 res-991-mb-35"></div>
                <div class="ttm-processbox-wrapper">
                  <div class="row">
                    <div class="col-lg-3">
                      <div class="ttm-box-content">
                        <div class="ttm-hovertext1">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-hovertext2">
                          <div class="process-num">
                            <div class="numbers">Güç Grup</div>
                          </div>
                        </div>
                        <div class="ttm-box-content-title res-991-pt-0">
                          <h3 class="ttm-box-title-h3">Kalite</h3>
                          <p>
                            {" "}
                            Her aşamada yüksek kaliteli malzeme ve işçilik
                            sağlıyoruz.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-9">
                      <div class="ttm-box-image res-991-mt-15">
                        <img
                          width="965"
                          height="240"
                          class="proces-img img-fluid"
                          src="images/step2.png"
                          loading="lazy"
                          alt="images"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ttm-row bg-img2 ttm-bgimage-yes ttm-bg bg-base-dark testimonial-section clearfix">
          <div class="ttm-row-wrapper-bg-layer ttm-bg-layer bg-base-dark"></div>
          <div class="container-fluid">
            <div class="row align-items-center spacing-2">
              <div class="col-lg-3">
                <div class="pr-20 res-1199-pr-0">
                  <div class="ttm-custom-counter-content">
                    <h3 class="ttm-custom-heading counter is-visible">500+</h3>
                    <h3 class="custom-heading-h3">Mutlu müşteri</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 res-991-mt-20">
                <Slider
                  className="row slick_slider slick-arrows-style4"
                  {...slick_slider}
                  slidesToShow={3}
                  arrows={false}
                  autoplay={false}
                  responsive={[
                    {
                      breakpoint: 1199,
                      settings: { slidesToShow: 2, slidesToScroll: 2 },
                    },
                    {
                      breakpoint: 991,
                      settings: { slidesToShow: 2, slidesToScroll: 1 },
                    },
                    {
                      breakpoint: 767,
                      settings: { slidesToShow: 1, slidesToScroll: 1 },
                    },
                  ]}
                >
                  <div className="col-lg-12">
                    <div class="testimonials-style1">
                      <div class="testimonials-style1-content">
                        <div class="testimonial-ttm-description">
                          <div class="ttm-box-highlight">
                            <h3 class="ttm-box-highlight-h3">
                              İnşaat Firma S.
                            </h3>
                          </div>
                          <div class="ttm-ratting-stars">
                            <ul class="ratting-stars">
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="testimonial-ttm-box-desc">
                          <p class="testimonial-ttm-box-desc-p">
                            "Güç Grup ile çalışmak projelerimizin tam zamanında
                            ve sorunsuz tamamlanmasını sağladı. Hem güvenilir
                            hem de profesyonel bir ekipleri var."
                          </p>
                        </div>
                        <div class="ttm-box-footer">
                          <h3 class="testimonial-caption-h3">Ali Y.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div class="testimonials-style1">
                      <div class="testimonials-style1-content">
                        <div class="testimonial-ttm-description">
                          <div class="ttm-box-highlight">
                            <h3 class="ttm-box-highlight-h3">
                              Proje Yöneticisi
                            </h3>
                          </div>
                          <div class="ttm-ratting-stars">
                            <ul class="ratting-stars">
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="testimonial-ttm-box-desc">
                          <p class="testimonial-ttm-box-desc-p">
                            "Taahhüt ettikleri her işi eksiksiz ve kalite
                            standartlarına uygun bir şekilde teslim ettiler. İş
                            birliğimiz boyunca her aşamada memnun kaldık."
                          </p>
                        </div>
                        <div class="ttm-box-footer">
                          <h3 class="testimonial-caption-h3">Merve K.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div class="testimonials-style1">
                      <div class="testimonials-style1-content">
                        <div class="testimonial-ttm-description">
                          <div class="ttm-box-highlight">
                            <h3 class="ttm-box-highlight-h3">Şantiye Şefi</h3>
                          </div>
                          <div class="ttm-ratting-stars">
                            <ul class="ratting-stars">
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                              <li class="ratting-stars-items">
                                <a href="#" class="ratting-stars-link">
                                  <i class="fa fa-star" aria-hidden="true"></i>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="testimonial-ttm-box-desc">
                          <p class="testimonial-ttm-box-desc-p">
                            "Güç Grup’un hafriyat ve nakliyat hizmetleriyle
                            büyük bir projemizi zamanında bitirebildik. Hem
                            ekipmanları hem de ekibi çok başarılı."
                          </p>
                        </div>
                        <div class="ttm-box-footer">
                          <h3 class="testimonial-caption-h3">Demir A.</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </section>

        <section class="ttm-row blog-section clearfix">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-6 col-md-12 col-sm-12">
                <div class="blog-title text-right">
                  <div class="section-title style2">
                    <div class="title-header">
                      <h2>Blog</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-12 col-sm-12 ">
                <div class="process-desc process-desc1">
                  <p class="desc-text">
                    Proje yönetimi ipuçları, sektördeki son gelişmeler ve
                    başarılı projelerimizin detaylarıyla dolu içerikler için
                    blogumuzu takip edin.
                  </p>
                </div>
              </div>
            </div>

            <Slider
              className="row slick_slider"
              {...slick_slider}
              slidesToShow={3}
              arrows={false}
              autoplay={false}
              responsive={[
                {
                  breakpoint: 1199,
                  settings: { slidesToShow: 3, slidesToScroll: 1 },
                },
                {
                  breakpoint: 991,
                  settings: { slidesToShow: 2, slidesToScroll: 1 },
                },
                {
                  breakpoint: 767,
                  settings: { slidesToShow: 2, slidesToScroll: 1 },
                },
                {
                  breakpoint: 480,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ]}
            >
              <div class="col-md-12">
                <div class="featured-imagebox-post-style1">
                  <div class="featured-post-overlay">
                    <div class="featured-post-thumbnail">
                      <img
                        class="img-fluid ttm-auto"
                        src="images/blog-insaat.jpg"
                        loading="lazy"
                        alt="image"
                      />
                    </div>
                    <div class="featured-post-content">
                      <div class="post-entry-date">10 Ağustos 2024</div>
                      <div class="ttm-post-title">
                        <h3 class="post-h3">
                          <a
                            href={process.env.PUBLIC_URL + "/insaat-nedir"}
                            class="post-link"
                          >
                            İnşaat Nedir? Temel Bilgiler ve Süreçler
                          </a>
                        </h3>
                      </div>
                      <div class="ttm-post-catagory">
                        <div class="catagory-text">Güç Grup / İnşaat</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="featured-imagebox-post-style1">
                  <div class="featured-post-overlay">
                    <div class="featured-post-thumbnail">
                      <img
                        class="img-fluid ttm-auto"
                        src="images/blog-hafriyat.jpg"
                        loading="lazy"
                        alt="image"
                      />
                    </div>
                    <div class="featured-post-content">
                      <div class="post-entry-date">16 Eyl 2024</div>
                      <div class="ttm-post-title">
                        <h3 class="post-h3">
                          <a
                            href={process.env.PUBLIC_URL + "/hafriyat-nedir"}
                            class="post-link"
                          >
                            Hafriyat Nedir? Bilinmesi Gerekenler
                          </a>
                        </h3>
                      </div>
                      <div class="ttm-post-catagory">
                        <div class="catagory-text">Güc Grup / Hafriyat</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="featured-imagebox-post-style1">
                  <div class="featured-post-overlay">
                    <div class="featured-post-thumbnail">
                      <img
                        class="img-fluid ttm-auto"
                        src="images/blog-lojistik.jpg"
                        loading="lazy"
                        alt="image"
                      />
                    </div>
                    <div class="featured-post-content">
                      <div class="post-entry-date">01 Aralık 2023</div>
                      <div class="ttm-post-title">
                        <h3 class="post-h3">
                          <a
                            href={
                              process.env.PUBLIC_URL + "/lojistik-calismalari"
                            }
                            class="post-link"
                          >
                            Lojistik Alanında Yapılan Çalışmalar: Verimlilik ve
                            Teknoloji Odaklı Gelişmeler
                          </a>
                        </h3>
                      </div>
                      <div class="ttm-post-catagory">
                        <div class="catagory-text">Güc Grup / Lojistik</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="col-md-12">
                <div class="featured-imagebox-post-style1">
                  <div class="featured-post-overlay">
                    <div class="featured-post-thumbnail">
                      <img
                        class="img-fluid ttm-auto"
                        src="images/blog/blog-04-828x894.png"
                        loading="lazy"
                        alt="image"
                      />
                    </div>
                    <div class="featured-post-content">
                      <div class="post-entry-date">22 Dec 2020</div>
                      <div class="ttm-post-title">
                        <h3 class="post-h3">
                          <a
                            href={process.env.PUBLIC_URL + "/Single_blog"}
                            class="post-link"
                          >
                            5 Easy Ways to Improve Your Web Security
                          </a>
                        </h3>
                      </div>
                      <div class="ttm-post-catagory">
                        <div class="catagory-text">By John Doe</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </Slider>
          </div>
        </section>

        <section class="ttm-row ttm-bg ttm-bgcolor-gradient client-section clearfix">
          <div class="container-fluid">
            <Slider
              className="row slick_slider"
              {...slick_slider}
              slidesToShow={1}
              arrows={false}
              autoplay={false}
              responsive={[
                {
                  breakpoint: 1199,
                  settings: { slidesToShow: 4, slidesToScroll: 2 },
                },
                {
                  breakpoint: 991,
                  settings: { slidesToShow: 3, slidesToScroll: 1 },
                },
                {
                  breakpoint: 767,
                  settings: { slidesToShow: 2, slidesToScroll: 1 },
                },
                {
                  breakpoint: 575,
                  settings: { slidesToShow: 1, slidesToScroll: 1 },
                },
              ]}
            >
              <div className="ttm-box-col-wrapper">
                <div className="client-box">
                  <div className="client">
                    <div className="ttm-client">
                      <img src="images/navbarlogo.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default Home;
