import React, { Component } from "react";
import Header from "../layout/Header";

export class constructionBlog extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box">
                  <div className="page-title-heading">
                    <h1 className="title">
                      İnşaat Nedir? Temel Bilgiler ve Süreçler
                    </h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Anasayfa" href={process.env.PUBLIC_URL + "/"}>
                        &nbsp;&nbsp;Anasayfa
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>İnşaat Nedir? Temel Bilgiler ve Süreçler</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section class="ttm-row clearfix">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ttm-blog-single position-relative pl-160 pr-160 res-1199-pr-0 res-1199-pl-0 res-1199-pl-0 res-1199-pr-0">
                  <div class="ttm_single_image-wrapper mb-25">
                    <img
                      width="1200"
                      height="895"
                      class="img-fluid"
                      src="images/blog-insaat.jpg"
                      loading="lazy"
                      alt="post-img"
                    />
                  </div>
                  <p>
                    İnşaat, insanlık tarihinin en eski ve en temel
                    faaliyetlerinden biridir. İnsanlar barınma, çalışma ve
                    yaşama alanlarına ihtiyaç duydukları için inşaat sektörü,
                    zamanla büyük bir endüstriye dönüşmüştür. İnşaat terimi,
                    yapıların tasarımından başlayarak, yapım süreci ve
                    tamamlanmasına kadar geçen aşamaları kapsayan geniş bir
                    kavramdır. Bu süreçte mimarlar, mühendisler, müteahhitler,
                    işçiler ve birçok farklı uzman birlikte çalışarak bir yapıyı
                    hayata geçirirler. İnşaatın Temel Süreçleri İnşaat süreci,
                    planlama ve tasarımdan başlayarak yapı kullanımına hazır
                    hale getirilmesine kadar bir dizi adımı içerir. Bu adımların
                    her biri büyük bir özen ve uzmanlık gerektirir. <br></br>{" "}
                    <br></br> 1.⁠ ⁠Planlama ve Tasarım İnşaat sürecinin ilk
                    adımı, ihtiyaçların belirlenmesi ve buna uygun bir tasarım
                    oluşturulmasıdır. Mimarlar ve mühendisler, bu aşamada
                    yapının işlevselliğini, estetiğini ve güvenliğini göz önünde
                    bulundurarak projeler oluştururlar. Ayrıca çevre şartları,
                    yasal düzenlemeler ve bütçe kısıtlamaları gibi faktörler de
                    bu aşamada dikkate alınır.<br></br>
                    <br></br> 2.⁠ ⁠Malzeme Seçimi Bir yapının sağlam ve
                    dayanıklı olabilmesi için doğru malzemelerin seçilmesi çok
                    önemlidir. İnşaatta kullanılan başlıca malzemeler arasında
                    beton, çelik, tuğla, ahşap ve cam yer alır. Malzemeler,
                    binanın amacına ve bulunduğu çevre koşullarına göre seçilir.
                    <br></br>
                    <br></br>3.⁠ ⁠Kazı ve Temel Atma Planlama ve malzeme seçimi
                    tamamlandıktan sonra, inşaat sahasında kazı işlemleri
                    başlar. Bu aşamada zeminin yapısı dikkatlice incelenir ve
                    yapının oturacağı temelin uygun bir şekilde hazırlanması
                    sağlanır. Temel, binanın güvenliğini ve sağlamlığını garanti
                    altına alan en kritik kısımdır. <br></br>
                    <br></br>4.⁠ ⁠Yapının İnşası Temel atıldıktan sonra, yapı
                    yükselmeye başlar. Bu aşamada, iskelet sistemi (kolonlar,
                    kirişler vb.), duvarlar, çatı ve diğer yapı elemanları inşa
                    edilir. Çoğu büyük projede betonarme sistemler kullanılır,
                    ancak çelik yapı sistemleri ve ahşap çerçeveler de yaygın
                    olarak tercih edilir.<br></br>
                    <br></br> 5.⁠ ⁠İç ve Dış Dekorasyon Yapının dış cephesi ve
                    iç dekorasyonu, estetik ve işlevselliği sağlamak için büyük
                    önem taşır. Bu aşamada duvarlar boyanır, zeminler döşenir,
                    pencereler ve kapılar monte edilir. Ayrıca tesisat
                    (elektrik, su, ısıtma-soğutma) sistemleri de bu süreçte
                    kurulur.<br></br>
                    <br></br> 6.⁠ ⁠Kontrol ve Teslimat İnşaatın tamamlanmasının
                    ardından, yapı yetkililer tarafından incelenir ve onaylanır.
                    Bu aşamada, inşaatın projeye uygun yapılıp yapılmadığı,
                    güvenlik ve kalite standartlarına uyup uymadığı kontrol
                    edilir. Tüm bu denetimler başarıyla tamamlandığında, yapı
                    kullanıma hazır hale gelir. İnşaat Türleri İnşaat sektörü,
                    çeşitli alt kategorilere ayrılabilir. Bunlar arasında: Konut
                    İnşaatı: Evler, apartmanlar ve diğer konut yapıları. Ticari
                    İnşaat: İş merkezleri, alışveriş merkezleri, oteller ve
                    diğer ticari yapılar. Altyapı İnşaatı: Köprüler, yollar, su
                    ve enerji sistemleri gibi kamu hizmetlerine yönelik
                    projeler. Endüstriyel İnşaat: Fabrikalar, enerji santralleri
                    ve depolama tesisleri gibi endüstriyel amaçlı yapılar.
                    İnşaatın Toplum İçin Önemi İnşaat sektörü, modern toplumun
                    gelişimi için hayati bir rol oynar. Bu sektör, barınma,
                    ulaşım, ticaret ve sanayi gibi temel ihtiyaçları
                    karşılamakla kalmaz, aynı zamanda milyonlarca insana iş
                    imkanı sağlar. Ayrıca, şehirlerin gelişimi ve çevre
                    düzenlemeleri, inşaat projeleri aracılığıyla
                    gerçekleştirilir. Ancak, inşaat projelerinin çevresel
                    etkileri de göz ardı edilmemelidir. Sürdürülebilir ve çevre
                    dostu inşaat uygulamaları, kaynakları korumak ve gelecek
                    nesillere yaşanabilir bir dünya bırakmak için önemlidir.
                    Sonuç İnşaat, sadece binalar ve altyapılar inşa etmekle
                    kalmaz, aynı zamanda toplumların şekillenmesine de katkı
                    sağlar. Teknolojik ilerlemeler ve sürdürülebilir yaklaşımlar
                    sayesinde, gelecekte inşaat sektörü daha çevre dostu ve
                    verimli hale gelecektir. Bu nedenle inşaat sektörü, hem
                    bugünün hem de yarının dünyasında kritik bir role sahiptir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default constructionBlog;
