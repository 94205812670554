import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";

export class Contact_01 extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box">
                  <div className="page-title-heading">
                    <h1 className="title">İletişime Geç</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        &nbsp;&nbsp;Ana Sayfa
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>İletişime Geç</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section class="ttm-row bg-layer-equal-height res-991-pb-0 res-991-pt-0 clearfix">
          <div class="container">
            <div class="row no-gutters">
              <div class="col-lg-6">
                <div class="ttm-col-bgcolor-yes ttm-bgcolor-darkgrey ttm-textcolor-white col-bg-img-five ttm-col-bgimage-yes ttm-bg">
                  <div class="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-darkgrey">
                    <div class="ttm-col-wrapper-bg-layer-inner ttm-bgcolor-darkgrey"></div>
                  </div>
                  <div class="layer-content map-contect-form">
                    <div class="section-title">
                      <div class="title-header mb-0">
                        <h2 class="title">
                          Bizimle İletişime Geçin: Sorularınız ve Talepleriniz
                          İçin Buradayız!
                        </h2>
                      </div>
                    </div>
                    <form
                      action="#"
                      class="query_form wrap-form clearfix mt-25 res-991-mt-0 position-relative"
                      method="post"
                    >
                      <div class="row">
                        <div class="col-md-12">
                          <label>
                            <span class="text-input" />
                            <input
                              name="name"
                              type="text"
                              placeholder="İsim"
                              required="required"
                            />
                          </label>
                        </div>
                        <div class="col-md-12">
                          <label>
                            <span class="text-input" />
                            <input
                              name="email"
                              type="text"
                              placeholder="E-Posta"
                              required="required"
                            />
                          </label>
                        </div>
                        <div class="col-md-12">
                          <label>
                            <span class="text-input">
                              <input
                                name="message"
                                type="text"
                                placeholder=" Telefon"
                                required="required"
                              ></input>
                            </span>
                          </label>
                        </div>
                        <div class="col-md-12">
                          <label>
                            <span class="text-input">
                              <textarea
                                name="message"
                                rows="5"
                                placeholder=" Mesajınız"
                                required="required"
                              ></textarea>
                            </span>
                          </label>
                        </div>
                        <div class="col-md-12">
                          <label>
                            <span class="checkbox">
                              <input
                                type="checkbox"
                                name="cookies-consent"
                                className="mr-10"
                                id="cookies-consent2"
                              />
                              <a href="#"> Hizmet Koşulları</a> ve{" "}
                              <a href="#"> Gizlilik Politikası.</a>
                            </span>
                          </label>
                        </div>
                        <div class="col-md-12">
                          <div class="mt-25">
                            <button
                              class="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-fill ttm-btn-color-gradiant"
                              type="submit"
                            >
                              Kaydet
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="ttm-bg ttm-bgcolor-grey spacing-6">
                  <div class="section-title mb-15">
                    <div class="title-header mb-0">
                      <h2 class="title">İletişime geçin</h2>
                    </div>
                    <div class="title-desc mb-40">
                      <p>
                        İnşaat, hafriyat, nakliyat ve taahhüt hizmetlerimizle
                        ilgili her türlü soru, talep ve iş birliği için bizimle
                        iletişime geçebilirsiniz. Profesyonel ekibimiz size en
                        kısa sürede dönüş yapacaktır.
                      </p>
                    </div>
                  </div>
                  <div class="contact-address-box">
                    <div class="address-box-info">
                      <h3 class="address-box-heading">Merkez Ofis</h3>
                      <div class="address-box-text">
                        Ihsaniye Mh, Ertuğrul Sk, Kazak Apt: No:2/4 D, <br />{" "}
                        Bolu Merkez/Bolu
                      </div>
                    </div>
                    <div class="address-box-map-link">
                      <a
                        href="https://www.google.com/maps/place//data=!4m2!3m1!1s0x409d3f119b5a476b:0xfd2f8910d923f088?sa=X&ved=1t:8290&ictx=111"
                        class="address-box-link"
                      >
                        Haritada Gör
                      </a>
                    </div>
                  </div>
                  {/* <div class="contact-address-box last-child">
                    <div class="address-box-info">
                      <h3 class="address-box-heading">Maryam jakarta</h3>
                      <div class="address-box-text">
                        Schweizer Skischule lohnerhubels <br /> goergam Mtrasse
                        95 CH-3123 Belp
                      </div>
                    </div>
                    <div class="address-box-map-link">
                      <a
                        href="https://www.google.com/maps"
                        class="address-box-link"
                      >
                        View On Map
                      </a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="ttm-row bg-base-grey map-accordion-section ttm-bgcolor-grey clearfix">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-12">
                <div class="contentmap">
                  <div class="section-title">
                    <div class="title-header">
                      <h2 class="title">Sık Sorulan Sorular</h2>
                    </div>
                    {/* <div class="title-desc">
                      <p>
                        Web designing in a powerful way of just not a
                        profession, however for our Company. We have a tendency
                        to believe the idea that smart looking to believe the
                        idea that smart looking.
                      </p>
                    </div> */}
                    <div class="mt-15">
                      <h3 class="contact-heading-h3">Social media</h3>
                      <ul class="social-icons map-links res-991-mb-20">
                        {/* <li>
                          <a
                            href="https://www.facebook.com/preyantechnosys19"
                            rel="noopener"
                            aria-label="facebook"
                          >
                            Facebook
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/PreyanTechnosys"
                            rel="noopener"
                            aria-label="twitter"
                          >
                            Twitter
                          </a>
                        </li> */}
                        <li>
                          <a
                            href="https://www.instagram.com/hafriyatguc"
                            rel="noopener"
                            aria-label="instagram"
                          >
                            Instagram
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-7 col-md-12">
                <Accordion preExpanded={["foo"]} className="accordion style3">
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        01. İnşaat projelerinizde hangi hizmetleri sunuyorsunuz?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        İnşaat projelerimizde altyapı, üstyapı, kaba ve ince
                        inşaat işleri dahil olmak üzere anahtar teslim çözümler
                        sunuyoruz.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        02. Hafriyat çalışmalarında hangi ekipmanları
                        kullanıyorsunuz?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Proje gereksinimlerine göre ekskavatör, yükleyici, dozer
                        ve kamyon gibi modern hafriyat makineleri kullanıyoruz.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        03. Nakliyat hizmetleriniz hangi bölgeleri kapsıyor?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Nakliyat hizmetlerimiz Türkiye genelinde tüm illeri
                        kapsamaktadır. İhtiyaç duyduğunuz malzemeleri güvenle ve
                        zamanında teslim ediyoruz.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        04. Taahhüt hizmetlerinizde hangi projeleri
                        üstleniyorsunuz?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        Konut, ticari bina ve altyapı projeleri gibi geniş bir
                        yelpazede anahtar teslim taahhüt hizmetleri sunuyoruz.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem>
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        05. Fiyat teklifini nasıl alabilirim?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                      <p>
                        htiyacınız olan hizmetlerle ilgili detayları bize
                        ilettiğinizde, en kısa sürede size özel bir fiyat
                        teklifi hazırlıyoruz. Teklif almak için iletişim
                        sayfamızdan bizimle iletişime geçebilirsiniz.
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </section>

        <section class="ttm-row zero-padding-section overflow-hidden clearfix">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 p-0">
                <div id="google_map" class="google_map">
                  <div class="map_container">
                    <div id="map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.077980444331!2d31.610395599999997!3d40.7383096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x409d3f119b5a476b%3A0xfd2f8910d923f088!2zQm9sdSB8IEfDnMOHIEdydXAgSW7Fn2FhdCBIYWZyaXlhdCBUYWFoaMO8dCB8!5e0!3m2!1str!2str!4v1727516107821!5m2!1str!2str"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                  <div class="map-overlay">
                    <div class="ttm-map-location">
                      <h3>Bizimle iletişime geçin</h3>
                      <p>
                        Ihsaniye Mh, Ertuğrul Sk, Kazak Apt: No:2/4 Merkez/Bolu
                      </p>
                      <ul class="map-list">
                        <li>
                          E-posta :{" "}
                          <a href="mailto:info@gucgrup.com" class="ttm-maplink">
                            info@gucgrup.com
                          </a>
                        </li>
                        <li>
                          Telefon Numarası :{" "}
                          <a href="tel:+905321652722" class="ttm-maplink">
                            0532 165 27 22
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="ttm-map-location mt-20">
                      <h3>Çalışma Saatlerimiz</h3>
                      <ul class="map-list">
                        <li>
                          Pzt : Cuma: <span>8:00 to 21:00</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Contact_01;
