import React, { Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import Header from "../layout/Header";

export class Excavation extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row-01">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box">
                  <div className="page-title-heading">
                    <h1 className="title">Nakliyat</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Homepage" href={process.env.PUBLIC_URL + "/"}>
                        Ana Sayfa
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Nakliyat</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div class="ttm-row sidebar ttm-sidebar-left clearfix">
          <div class="container">
            <div class="row">
              <div class="col-lg-4 widget-area sidebar-left ttmcol-bgcolor-yes ttm-bg ttm-left-span">
                <div class="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-grey"></div>
                {/* <aside class="widget widget-search with-title">
                  <form
                    role="search"
                    method="get"
                    class="search-form"
                    action="#"
                  >
                    <label>
                      <span class="input-text">
                        <input
                          name="Search"
                          type="text"
                          placeholder="Search"
                          required="required"
                        ></input>
                      </span>
                    </label>
                    <button class="btn" type="submit"></button>
                  </form>
                </aside> */}
                <aside class="widget widget-nav-menu with-title">
                  <div class="widget-title">
                    <h3>Hizmetlerimiz</h3>
                  </div>
                  <ul>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/insaat"}> İnşaat </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/hafriyat"}>
                        {" "}
                        Hafriyat{" "}
                      </a>
                    </li>
                    <li class="active">
                      <a href={process.env.PUBLIC_URL + "/nakliyat"}>
                        {" "}
                        Nakliyat{" "}
                      </a>
                    </li>
                    <li>
                      <a href={process.env.PUBLIC_URL + "/taahhut"}>
                        {" "}
                        Taahhüt{" "}
                      </a>
                    </li>
                  </ul>
                </aside>

                <aside class="widget widget-banner with-title">
                  <div class="ttm-col-bgcolor-yes ttm-bgcolor-darkgrey ttm-textcolor-white col-bg-img-three ttm-col-bgimage-yes ttm-bg">
                    <div class="ttm-col-wrapper-bg-layer ttm-bg-layer ttm-bgcolor-darkgrey">
                      <div class="ttm-col-wrapper-bg-layer-inner ttm-bgcolor-darkgrey"></div>
                    </div>
                    <div class="layer-content text-center position-relative">
                      <h3 class="sidebar-banner-heading">Güç Grup</h3>
                      <div class="sidebar-banner-subheading">
                        Size yardımcı olabiliriz.
                      </div>
                      <a
                        href="tel:+905321652722"
                        class="sidebar-banner-phone-link"
                      >
                        0532 165 27 22
                      </a>
                      <a
                        class="ttm-btn ttm-btn-size-md ttm-btn-shape-round ttm-btn-style-fill ttm-btn-color-gradiant"
                        href={process.env.PUBLIC_URL + "/iletisim"}
                      >
                        Bizimle İletişime Geç
                      </a>
                    </div>
                  </div>
                </aside>
              </div>
              <div class="col-lg-8 content-area">
                <div class="ttm-service-single-content-area">
                  <div class="ttm_fatured_image-wrapper mb-40 res-575-mb-20">
                    <img
                      width="859"
                      height="440"
                      class="img-fluid"
                      src="images/logistic.jpg"
                      alt="services-1"
                    />
                  </div>
                  <div class="ttm-service-description">
                    <h3>Güç Grup Nakliyat Hizmetleri</h3>
                    <p>
                      Güç Grup olarak, inşaat ve lojistik sektörlerinde
                      güvenilir nakliyat hizmetleri sunuyoruz. Projelerinizin
                      ihtiyacı olan malzemeleri, geniş araç filomuz ve deneyimli
                      ekibimizle zamanında ve sorunsuz bir şekilde teslim
                      ediyoruz. Türkiye genelinde sunduğumuz nakliyat
                      hizmetleriyle, hem büyük ölçekli projeler hem de küçük
                      çaplı taşımacılık işlerinizde yanınızdayız. Yüksek
                      güvenlik standartlarımız sayesinde malzemelerinizin sağlam
                      şekilde taşınmasını garanti ediyoruz. Projelerinizin
                      aksamaması için hızlı, planlı ve güvenilir nakliyat
                      çözümleri arıyorsanız, Güç Grup’un profesyonel
                      hizmetleriyle tanışın.
                    </p>
                    <div class="ttm_fatured_image-wrapper mb-40 res-575-mb-20">
                      <img
                        width="420"
                        height="220"
                        class="img-fluid"
                        src="images/logistic2.jpg"
                        alt="services-1"
                      />
                    </div>
                    {/* <div class="row">
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <ul class="service-detail-list">
                          <li class="service-detail-list-item">
                            <div class="service-detail-list-text">
                              Seize opportunities and spark
                            </div>
                          </li>
                          <li class="service-detail-list-item">
                            <div class="service-detail-list-text">
                              Experience to share goals
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div>
                          <ul class="service-detail-list">
                            <li class="service-detail-list-item">
                              <div class="service-detail-list-text">
                                Comprehensive testing
                              </div>
                            </li>
                            <li class="service-detail-list-item">
                              <div class="service-detail-list-text">
                                Innovate &amp; create great
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-40 res-767-mt-20">
                      <div class="col-xl-7 col-lg-7 col-md-12">
                        <h3>How we work</h3>
                        <div class="mt-35">
                          <div class="timeline-block">
                            <div class="iconbox-sd">
                              <div class="iconbox-num-block">
                                <div class="iconbox-num"></div>
                              </div>
                              <div class="iconbox-content-sd">
                                <div class="iconbox-title-sd">
                                  <h3 class="iconbox-heading-sd">
                                    Project planning
                                  </h3>
                                </div>
                                <div class="iconbox-desc-sd">
                                  <p class="iconbox-text-sd">
                                    Well aware of the existing mobile app market
                                    trends to keep tabs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="timeline-block">
                            <div class="iconbox-sd">
                              <div class="iconbox-num-block">
                                <div class="iconbox-num"></div>
                              </div>
                              <div class="iconbox-content-sd">
                                <div class="iconbox-title-sd">
                                  <h3 class="iconbox-heading-sd">
                                    Research &amp; analysis
                                  </h3>
                                </div>
                                <div class="iconbox-desc-sd">
                                  <p class="iconbox-text-sd">
                                    Well aware of the existing mobile app market
                                    trends to keep tabs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="timeline-block last-child">
                            <div class="iconbox-sd">
                              <div class="iconbox-num-block last-child">
                                <div class="iconbox-num"></div>
                              </div>
                              <div class="iconbox-content-sd">
                                <div class="iconbox-title-sd">
                                  <h3 class="iconbox-heading-sd">Deployment</h3>
                                </div>
                                <div class="iconbox-desc-sd">
                                  <p class="iconbox-text-sd">
                                    Well aware of the existing mobile app market
                                    trends to keep tabs.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-5 col-lg-5 col-md-12 res-991-mt-30">
                        <div class="ttm_fatured_image-wrapper">
                          <img
                            class="img-fluid border-rad-30"
                            src="images/bg-image/col-bgimage-2.jpg"
                            alt="services-1"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="mt-40 res-767-mt-20">
                      <div>
                        <h3 className="mb-30">Frequently asked questions</h3>
                      </div>
                      <Accordion className="accordion style2">
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              How can i find my solutions?
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p className="mb-0">
                              Sustainable development is the creed that
                              underpins Irecco’s bespoke initiatives to protect
                              the environment, strengthen communities and propel
                              responsible functions including supply chain
                              scheduling, manufacturing, services and spares,
                              technology.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              How much does solar cost?
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p className="mb-0">
                              Sustainable development is the creed that
                              underpins Irecco’s bespoke initiatives to protect
                              the environment, strengthen communities and propel
                              responsible functions including supply chain
                              scheduling, manufacturing, services and spares,
                              technology.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              What payment methods are available?
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p className="mb-0">
                              Sustainable development is the creed that
                              underpins Irecco’s bespoke initiatives to protect
                              the environment, strengthen communities and propel
                              responsible functions including supply chain
                              scheduling, manufacturing, services and spares,
                              technology.
                            </p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      </Accordion>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Excavation;
