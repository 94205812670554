import React from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from "./components/layout/Footer";
// pages
import Home from "./components/Home";
import Contact_01 from "./components/pages/Contact_01";
import Error from "./components/pages/Error";
import construction from "./components/services/construction";
import excavation from "./components/services/excavation";
import logistics from "./components/services/logistics";
import taahhut from "./components/services/taahhut";
import { Projects } from "./components/project/Project_style1";
import project_details from "./components/project/project_details";
import Blog from "./components/blog/Blog";
import { constructionBlog } from "./components/blog/insaat-nedir";
import ScrollToTop from "./components/layout/Gotop";
import excavationBlog from "./components/blog/hafriyat-nedir";
import logisticsBlog from "./components/blog/lojistik-calismalari";

const appHistory = {
  basename: process.env.PUBLIC_URL,
};
const history = createBrowserHistory(appHistory);
function App() {
  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>
        <ScrollToTop />
        <Route exact path="/" component={Home} />
        {/* <Route path="/aboutus_01" component={Aboutus_01} />
        <Route path="/services_01" component={Services_01} />
        <Route path="/Our_team" component={Our_team} />
        <Route path="/team_detail" component={Team_detail} />
        <Route path="/faq" component={Faq} /> */}
        <Route path="/iletisim" component={Contact_01} />
        <Route path="/error" component={Error} />
        <Route path="/insaat" component={construction} />
        <Route path="/hafriyat" component={excavation} />
        <Route path="/nakliyat" component={logistics} />
        <Route path="/taahhut" component={taahhut} />
        <Route path="/projeler" component={Projects} />
        {/* <Route path="/project_style2" component={Project_style2} /> */}
        <Route path="/project_details" component={project_details} />
        <Route path="/blog" component={Blog} />
        <Route path="/insaat-nedir" component={constructionBlog} />
        <Route path="/hafriyat-nedir" component={excavationBlog} />
        <Route path="/lojistik-calismalari" component={logisticsBlog} />
        {/* <Route path="/shop" component={Shop} />
        <Route path="/product_details" component={Product_details} /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
