import React, { Component } from "react";

export class Footer extends Component {
  render() {
    return (
      <footer class="footer widget-footer bg-base-dark clearfix">
        <div class="second-footer">
          <div class="container">
            <div class="row no-gutters">
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="footer-widget-box first-child">
                  {/*<h3 class="widget-title-h3">Bize Ulaşın</h3>
                   <div class="form-block">
                    <div class="widget-form">
                      <form
                        id="subscribe-form"
                        class="newsletter-form"
                        method="post"
                        action="#"
                        data-mailchimp="true"
                      >
                        <div
                          class="mailchimp-inputbox clearfix"
                          id="subscribe-content"
                        >
                          <p class="mb-0">
                            <input
                              type="email"
                              name="email"
                              placeholder="Your email"
                              required=""
                            />
                          </p>
                          <button class="submit" type="submit">
                            Send Now<i class="fas fa-long-arrow-alt-up"></i>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div> */}
                  <h3 class="widget-title-h3">Konumumuz:</h3>
                  <div class="ttm-iconbox">
                    <div class="footer-icon">
                      <div class="footer-icon-box">
                        <div class="footer-icon-link">
                          <i class="ti ti-location-pin"></i>
                        </div>
                      </div>
                      <div class="footer-box-content">
                        <p class="mb-0">
                          İhsaniye Mah. Ertuğrul Sok. Kazak Apt. No:2/4 D
                          Merkez/Bolu
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="footer-social-links-wrapper">
                    <ul class="footer-social-icons">
                      <li class="footer-social-icons-item">
                        <a
                          href="https://www.instagram.com/hafriyatguc"
                          class="footer-social-icons-link"
                        >
                          Instagram
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="footer-widget-box res-767-pt-45">
                  <h3 class="widget-title-h3">Hizmetlerimiz</h3>
                  <div class="footer-menu-links">
                    <ul class="footer-menu-list">
                      <li class="footer-menu-item">
                        <a href="/insaat" class="footer-menu-item-link">
                          İnşaat
                        </a>
                      </li>
                      <li class="footer-menu-item">
                        <a href="/hafriyat" class="footer-menu-item-link">
                          Hafriyat
                        </a>
                      </li>
                      <li class="footer-menu-item">
                        <a href="/nakliyat" class="footer-menu-item-link">
                          Nakliyat
                        </a>
                      </li>
                      <li class="footer-menu-item">
                        <a href="/taahhut" class="footer-menu-item-link">
                          Taahhüt
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="footer-widget-box last-child res-767-pt-25">
                  <div class="widget_nav_menu">
                    <div class="textwidget-widget-text">
                      <div class="ttm-numbertext">
                        <p class="ttm-numbertext-p">Telefon Numaramız</p>
                        <h3 class="ttm-numbertext-h3">
                          <a
                            href="tel:+905321652722"
                            class="ttm-numbertext-link"
                          >
                            0532 165 27 22
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="ttm-horizontal_sep mb-45 mt-25 res-991-mt-10 res-991-mb-25"></div>
                  <div class="widget_nav_menu">
                    <div class="textwidget-widget-text">
                      <div class="ttm-numbertext">
                        <p class="ttm-numbertext-p">E-Posta Adresimiz</p>
                        <h3 class="ttm-numbertext-h3">
                          <a
                            href="mailto:info@gucgrup.com"
                            class="ttm-numbertext-link"
                          >
                            info@gucgrup.com
                          </a>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="ttm-horizontal_sep mb-40 mt-25 res-991-mt-10 res-991-mb-25"></div>
                  <div class="widget_nav_menu">
                    <div class="textwidget-widget-text">
                      <div class="ttm-numbertext">
                        <p class="ttm-numbertext-p">
                          Copyright © 2024{" "}
                          <a
                            href="https://pinedigitals.com/"
                            class="ttm-numbertext-p-link"
                          >
                            Pine Digitals
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
