import React, { Component } from "react";
import { Menu } from "./menu";
import Mobilemenu from "./Mobile_menu";

export class Header extends Component {
  state = {
    show: false,
  };
  toggle = () =>
    this.setState((currentState) => ({ show: !currentState.show }));
  render() {
    return (
      <header id="masthead" className="header ttm-header-style-01">
        {/* ttm-header-wrap */}
        <div className="ttm-header-wrap">
          <div id="navbar" className="ttm-stickable-header clearfix">
            <div className="site-header-menu">
              <div className="container">
                {/* site-branding */}
                <div className="site-branding">
                  <a
                    className="home-link"
                    href={process.env.PUBLIC_URL + "/"}
                    title="Güç Grup İnşaat | Hafriyat | Nakliyat | Taahhüt"
                    rel="home"
                  >
                    <img
                      id="logo-img"
                      className="img-fluid"
                      alt="logo"
                      src="images/logo.svg"
                    />
                  </a>
                </div>
                {/* site-branding end*/}
                {/*site-navigation */}
                <div className="site-navigation">
                  <div className="ttm-right-header">
                    <div class="side-menu-container d-flex align-items-center">
                      <div class="header_call">
                        <span class="call-text position-relative ttm-textcolor-white">
                          İletişim:{" "}
                        </span>
                        <a
                          href="tel:+905321652722"
                          class="call_btn ttm-textcolor-white"
                        >
                          {" "}
                          (0532 165 27 22)
                        </a>
                      </div>
                    </div>
                  </div>
                  <Menu />
                  <div className="mobilemenu">
                    <Mobilemenu />
                  </div>
                </div>
                {/*site-navigation end*/}
              </div>
            </div>
          </div>
        </div>
        {/* ttm-header-wrap end*/}
      </header>
    );
  }
}

export default Header;
