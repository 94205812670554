import React, { Component } from "react";
import {
  MDBNavbar,
  MDBNavbarNav,
  MDBNavItem,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBHamburgerToggler,
} from "mdbreact";
import { BrowserRouter as Router } from "react-router-dom";

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: "",
  };

  toggleCollapse = (collapseID) => () => {
    this.setState((prevState) => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
    }));
  };

  toggleSingleCollapse = (collapseId) => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId],
    });
  };

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler
            id="hamburger1"
            onClick={() => this.toggleSingleCollapse("collapse1")}
          />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdown>
                  <MDBDropdownToggle nav>
                    <span>Anasayfa</span>
                  </MDBDropdownToggle>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Hizmetlerimiz</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/insaat"}>
                      İnşaat
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/hafriyat"}
                    >
                      Hafriyat
                    </MDBDropdownItem>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/nakliyat"}
                    >
                      Nakliyat
                    </MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + "/taahhut"}>
                      Taahhüt
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem>
              {/* <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav>
                    <span>Projeler</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem
                      href={process.env.PUBLIC_URL + "/projeler"}
                    >
                      Projeler
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavItem> */}
              <MDBNavItem>
                <a href={process.env.PUBLIC_URL + "/iletisim"}>
                  {" "}
                  <span>İletişim</span>
                </a>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;
