import React, { Component } from "react";
import Header from "../layout/Header";

export class excavationBlog extends Component {
  constructor(props) {
    super();
  }
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box">
                  <div className="page-title-heading">
                    <h1 className="title">
                      Hafriyat Nedir? Bilinmesi Gerekenler
                    </h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Anasayfa" href={process.env.PUBLIC_URL + "/"}>
                        &nbsp;&nbsp;Anasayfa
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Hafriyat Nedir? Bilinmesi Gerekenler</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <section class="ttm-row clearfix">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div class="ttm-blog-single position-relative pl-160 pr-160 res-1199-pr-0 res-1199-pl-0 res-1199-pl-0 res-1199-pr-0">
                  <div class="ttm_single_image-wrapper mb-25">
                    <img
                      width="1200"
                      height="895"
                      class="img-fluid"
                      src="images/blog-hafriyat.jpg"
                      loading="lazy"
                      alt="post-img"
                    />
                  </div>
                  <p>
                    Hafriyat, inşaat projelerinin en önemli aşamalarından biri
                    olan, kazı ve taşımacılık işlemlerini içeren bir süreçtir.
                    Büyük inşaat projelerinde temel hazırlığı, zemin düzeltme,
                    altyapı oluşturma ve istenmeyen materyallerin taşınması gibi
                    işlemler, hafriyatın temel unsurlarıdır. Peki, hafriyat tam
                    olarak nedir ve bu süreçte nelere dikkat edilmelidir? İşte
                    hafriyat hakkında bilinmesi gerekenler. Hafriyat Nedir?
                    Hafriyat, kelime anlamı olarak kazı işlemleriyle yerinden
                    çıkarılan toprak, taş, moloz ve diğer materyallerin
                    taşınmasını ifade eder. İnşaat projelerinde yapının güvenli
                    ve sağlam bir şekilde inşa edilebilmesi için zemin düzeltme
                    işlemleri kritik bir öneme sahiptir. Hafriyat işlemi
                    sırasında genellikle kazı makineleri, kamyonlar ve ağır iş
                    makineleri kullanılır. Bu süreç, büyük ölçüde planlama,
                    koordinasyon ve doğru ekipmanların kullanımı gerektirir.
                    Hafriyat Sürecinin Aşamaları Hafriyat, tek bir işlemden
                    oluşmaz; çeşitli aşamalardan geçen bir süreçtir. İşte bu
                    sürecin ana adımları: <br></br>
                    <br></br> 1. Planlama ve Hazırlık Hafriyat işlemine
                    başlamadan önce, proje sahasında zemin analizi yapılır ve
                    toprağın yapısına göre bir plan hazırlanır. Zemin yapısının
                    incelenmesi, hafriyatın ne kadar derin ve geniş yapılacağını
                    belirlemek açısından kritiktir. Ayrıca, bu aşamada gerekli
                    izinler alınır ve çevre güvenliği için önlemler planlanır.
                    <br></br>
                    <br></br>2. Kazı İşlemi Kazı işlemi, hafriyatın temelini
                    oluşturur. Bu işlem, binanın temelinin atılacağı alanı hazır
                    hale getirmek için yapılır. Kazı derinliği ve genişliği,
                    projenin türüne ve zemin yapısına göre belirlenir. Örneğin,
                    çok katlı bir bina için daha derin kazılar yapılırken, daha
                    küçük projelerde daha yüzeysel kazılar yapılabilir.<br></br>
                    <br></br> 3. Atık Taşımacılığı Kazı işlemi sonucunda ortaya
                    çıkan toprak, taş, moloz ve diğer atıklar sahadan taşınarak
                    uygun yerlere dökülür. Bu taşımacılık işlemi, çevre
                    düzenlemelerine uygun olarak yapılmalıdır. Hafriyat
                    atıklarının çevreye zarar vermemesi için belirlenen döküm
                    alanlarına taşınması gerekmektedir.<br></br>
                    <br></br> 4. Zemin Düzeltme ve Güçlendirme Kazı işlemi
                    tamamlandıktan sonra, zemin düzeltme işlemi yapılır. Bu
                    aşamada, zemin yapısı düzleştirilir ve gerekirse güçlendirme
                    çalışmaları yapılır. Zemin güçlendirme, yapının sağlam ve
                    güvenli bir şekilde inşa edilmesi için büyük önem taşır.
                    Hafriyatın Kullanım Alanları Hafriyat işlemi, birçok farklı
                    inşaat projesinde uygulanır. İşte hafriyatın yaygın kullanım
                    alanları: Bina Temel Kazıları: Her türlü konut ve ticari
                    yapı projesinde binanın temeli için hafriyat yapılır.
                    <br></br>
                    <br></br>Altyapı Çalışmaları: Yol, köprü, kanalizasyon, su
                    boruları ve enerji hatları gibi altyapı projelerinde de
                    hafriyat önemli bir rol oynar. Peyzaj Düzenlemeleri: Büyük
                    peyzaj projelerinde, arazi şekillendirme ve düzeltme
                    çalışmaları için hafriyat işlemleri gereklidir. Madencilik
                    ve Tünel Kazıları: Maden çıkarma veya tünel açma
                    projelerinde de hafriyat ve kazı çalışmaları yapılır.
                    Hafriyat İşlemi Sırasında Dikkat Edilmesi Gerekenler
                    Hafriyat süreci oldukça karmaşık ve dikkat gerektiren bir
                    işlem olduğundan, doğru planlama ve yönetim büyük önem
                    taşır. İşte hafriyat sırasında dikkat edilmesi gereken
                    önemli noktalar: <br></br>
                    <br></br> 1. Yasal Düzenlemelere Uyum Hafriyat işlemi
                    sırasında çevre yasalarına ve yönetmeliklere uyulması
                    zorunludur. Özellikle atık taşımacılığı ve döküm alanları
                    konusunda sıkı düzenlemeler bulunmaktadır. Yanlış veya
                    izinsiz döküm işlemleri hem çevreye zarar verebilir hem de
                    ağır para cezalarına yol açabilir.<br></br>
                    <br></br> 2. Çevre Koruma Hafriyat işlemleri sırasında
                    çevreye minimum zarar verilmesi hedeflenmelidir. Toz,
                    gürültü ve atıkların çevreye yayılmasını önlemek için
                    gerekli tedbirler alınmalıdır. Ayrıca hafriyat atıklarının
                    geri dönüştürülebilir olanlarının uygun şekilde işlenmesi
                    çevreye duyarlı bir yaklaşım olacaktır.<br></br>
                    <br></br> 3. Güvenlik Önlemleri Hafriyat sahasında
                    çalışanların güvenliği birinci önceliktir. İş makineleri ve
                    ağır ekipmanların kullanıldığı hafriyat sahalarında iş
                    güvenliği kurallarına sıkı bir şekilde uyulmalıdır. Kişisel
                    koruyucu ekipmanların kullanılması, sahaya giriş-çıkışların
                    düzenlenmesi ve saha güvenliğinin sağlanması kritik
                    önemdedir. <br></br>
                    <br></br>4. Doğru Ekipman Kullanımı Hafriyatın hızlı ve
                    verimli bir şekilde gerçekleştirilmesi için doğru
                    ekipmanların kullanılması gereklidir. Ekskavatörler,
                    kamyonlar, dozerler gibi ağır iş makineleri, hafriyatın
                    türüne ve büyüklüğüne göre seçilmelidir. Hafriyatın Önemi
                    Hafriyat, inşaat projelerinin temelini oluşturur. Zemin
                    hazırlığı yapılmadan inşaata başlamak, projede ciddi
                    sorunlara yol açabilir. Sağlam bir temel oluşturmak ve zemin
                    düzeltme çalışmaları yapmak, yapıların güvenliği açısından
                    hayati öneme sahiptir. <br></br>
                    <br></br>Ayrıca altyapı projelerinin düzgün bir şekilde
                    yürütülmesi ve şehirlerin modernleşmesi için hafriyat
                    işlemleri büyük bir rol oynar. Sonuç Hafriyat, inşaat
                    projelerinin vazgeçilmez bir parçasıdır. Doğru şekilde
                    yönetilen hafriyat işlemleri, projelerin sağlam temeller
                    üzerine inşa edilmesini sağlar. Ancak çevresel faktörler ve
                    yasal düzenlemeler göz önünde bulundurularak, hafriyat
                    işlemlerinin çevreye zarar vermeden yürütülmesi büyük önem
                    taşır. Hafriyat, bir inşaat projesinin başarısında kritik
                    bir rol oynar ve bu nedenle titizlikle planlanması gereken
                    bir süreçtir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default excavationBlog;
