import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from "../layout/Header";

export class Projects extends Component {
  render() {
    return (
      <div className="site-main">
        <Header />
        {/* page-title */}
        <div className="ttm-page-title-row">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="title-box">
                  <div className="page-title-heading">
                    <h1 className="title">Projeler</h1>
                  </div>
                  {/* /.page-title-captions */}
                  <div className="breadcrumb-wrapper">
                    <span>
                      <a title="Anasayfa" href={process.env.PUBLIC_URL + "/"}>
                        &nbsp;&nbsp;Anasayfa
                      </a>
                    </span>
                    <span className="ttm-bread-sep">&nbsp; : : &nbsp;</span>
                    <span>Projeler</span>
                  </div>
                </div>
              </div>
              {/* /.col-md-12 */}
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* page-title end*/}
        <div className="ttm-row tab-section clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-12">
                <div className="ttm-tabs text-center ttm-tab-style-classic style3">
                  <Tabs>
                    <TabList className="text-uppercase mb-4">
                      <Tab>
                        <span>Tümü</span>
                      </Tab>
                      <Tab>
                        <span>Devam Eden</span>
                      </Tab>
                      <Tab>
                        <span>Biten</span>
                      </Tab>
                      <Tab>
                        <span>Planlanan</span>
                      </Tab>
                    </TabList>
                    <div className="content-tab">
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT consultancy
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Business
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Technology
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud services
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Business
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Product design
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Big data analytics
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Workshop
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud computing
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT management
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Business
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Technology
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud services
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Business
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Big data analytics
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT consultancy
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Big data analytics
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Workshop
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud computing
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Technology
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT consultancy
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Technology
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT management
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Product design
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Business
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud services
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Workshop
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Product design
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Workshop
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Corporate
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Big data analytics
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="row multi-columns-row">
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Innovation
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Technology
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        Cloud services
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-6 project_item campaign corporate ttm-box-col-wrapper">
                            <div class="featured-imagebox featured-imagebox-portfolio style1">
                              <div class="featured-link">
                                <div class="featured-thumbnail">
                                  <img
                                    class="img-fluid"
                                    width="635"
                                    height="800"
                                    src="images/portfolio/pf-04-635x800.jpg"
                                    alt="gallery-img"
                                  />
                                </div>
                                <div class="imagebox-portfolio-bg-overlay"></div>
                                <div class="imagebox-content-ttmfolio">
                                  <div class="imagebox-category-portfolio">
                                    <a href="#" class="category-portfolio">
                                      Campaign
                                    </a>
                                    <div class="category-sep">,</div>
                                    <a href="#" class="category-portfolio">
                                      Innovation
                                    </a>
                                  </div>
                                  <div class="imagebox-title-portfolio">
                                    <h3 class="imagebox-heading-portfolio">
                                      <a
                                        href={
                                          process.env.PUBLIC_URL +
                                          "/project_details"
                                        }
                                        class="imagebox-heading-portfolio-link"
                                      >
                                        IT management
                                      </a>
                                    </h3>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </div>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Projects;
